.navbar {
  display: flex;
  align-items: center;
  background-color:transparent;

}
.custom-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
}


.lg-left{
  margin-left: 2rem;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  color:#222222;
  
}
.logo::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  background: orangered;
  
}

.nav-link {
  padding: 1rem;
  margin-right: 2rem;
  color:  black;
  font-size: 18px;
  cursor: pointer;
}
.nav-link:hover{
  color:orangered !important;
  transition: color 0.6s ease !important;
}

.ml-auto {
  margin-left: auto;
}


