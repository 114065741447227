.container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding:250px;

}

.inner-content {
    position: relative;
    bottom: 15px;
    max-width: 875px;
    align-items: center;
    text-align:center;
    width: 100%;
   
   
  
}


.inner-content p{
  font-size: 18px;
  color: #f5f5f5;
  letter-spacing: 1px;
  line-height: 27px;
  margin-bottom: 2rem;
  

}

 span.my-name {
  color: orangered;
 
}

.hero-banner {
  h1 {
    font-size: 70px;
    letter-spacing: 0.12rem;
    color: whitesmoke;
    padding-bottom: 1px;
    font-weight: bolder;
  }
  h2 {
    font-size: 28px;
    color: whitesmoke;
    font-weight: bold;
    margin-bottom: 2rem;
    letter-spacing: 1px;
   
  }
}

.btn-container{
  display: inline;

  
}


 #github-btn {
  display: inline;
  padding: 10px 20px;
  background-color: dodgerblue;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  letter-spacing: 1px;
  margin-top:2.6rem;
  text-decoration: none;
  cursor:pointer;
  
 }
 .github-icon {
  vertical-align: middle;
  margin-right: 5px;
  text-align:center;
  font-size:20px;

 }
 

/* media query*/
@media (max-width: 767px) {
  .container {
    flex-direction: column;
    padding: 150px;
    
  }
  .inner-content{
    bottom: 8px;
  }
  

   .about-img{
    text-align: center ;
    margin-bottom: 2rem;

  }

  .about-content{
    width: 100%;
    padding:0;
    margin-top:0;
    text-align: center;
  }

  h2{
    margin-bottom: 2rem;
    
  }
}


@media (min-width: 768px) and (max-width: 991px) {
  .container {
    flex-direction: row;
  }
  
  .inner-content {
    max-width: 450px;
    width: 100%;
    padding:0;
    margin-top:5px;
    text-align: left;
    text-align:center;
  }

  h1{
    padding-top: 4.5rem;
    
  }
}
