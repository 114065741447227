
.about-background{
  background-color: #f2f3f4;
  padding: 5rem;
 
}
.about-container{
 height: auto;
 display: flex;
 flex-direction: row;
 justify-content: center;
 margin: 0 auto;
 width: 100%;
 border-radius: 5px;
 max-width: 1340px;
 
 
}

.about-img{
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 310px;
  height:auto;
  box-shadow:  0 8px 10px rgba(0, 0, 0, 0.2);

  
}
.img-fluid{
  border-radius: 5px;

}

.about-content h1 {
  font-size: 33px; 
  color:black;
  margin-bottom:1.3rem;
  

}

.about-content p {
  margin-top:0;
  color: black;
  letter-spacing: 1.2px;
  line-height: 27px;
  text-align: justify;
 

}

