.skills-section{
  background-color: rgb(245,245,245);
  padding-bottom: 95px;

}
.h1-skills{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.h1-skills h1{
  text-align:center;
}

.skills-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  padding-top: 80px;
  justify-items: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  
}

.skill-card {
  width: 100%;
  max-width: 282px;
  background-color: rgba(120, 116, 116, 0.4);
  padding: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;


}
.skill-card i {
  opacity: 1;
  transition: opacity 0.0s ease-in-out;
  color: rgb(28,40,65);
}
.skill-card:hover i{
  opacity: 0;
}

.skill-card:hover,
.project-logo:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  background-color:#a9a9a9;
  letter-spacing: 1.2px;
  
  
}

.skill-card:hover h1 {
  display:block;
}
.hover-text {
  display: none;
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size:24px;
  font-weight: bolder;
}
.skill-card:hover .hover-text {
  display:block;
}


.skills-heading {
  color: black;
  justify-content: center;
  margin-top: 80px;
 
}

.fa-brands {
  font-size: 60px;
  
  
}
.hover-effect {
  animation: dance 2s infinite;
}

@keyframes dance {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(10deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
}



/* Media Queries */
@media (max-width: 768px) {
  .skills-content {
    grid-template-columns:1fr;
  }

  .skill-card {
    max-width: 400px;
  }
}
