 .contact-section {
  margin: 5rem auto;
  max-width:650px;
  width:100%;
 
 }

 .contact-h1  {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.inner-contact{
  display: block;
  align-items: center;
  justify-content: center;
  padding:15px;
}

.contact-h1 h1, .contact-p{
  text-align: center;
  margin-bottom: 3rem;
}


.inner-contact a {
  text-decoration: none;
  color: rgb(22, 115, 209);
}
.inner-contact p{
  letter-spacing: 1.3px;
  line-height: 24px;
  color: black;
}
.inner-contact a:hover{
  color:orangered;
}

