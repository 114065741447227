  /* for polaroid */
  .img-fluid {
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    
   }
   
  /*background-image*/
  .background {
    position: relative;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    overflow: hidden;
    background-Image: url("../Images/cat-han-unsplash.jpg");
    filter: brightness(95%);
    
  }

 


