/* Social Links*/
.footer-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #000000;
  
}
.social-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.social-links li {
  margin: 8px 32px 20px;
  
 
}

.social-links li a {
  color: whitesmoke;
  font-size: 25px;
}
.social-links li a:hover{
  color:orangered;
  transition: 0.8s;
}
